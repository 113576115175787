import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

// Connects to data-controller="system-loads"
export default class extends Controller {
  static values = {
    current: Array,
    compareTo: Array,
    compareWith: Object,
    currentLoadDiff: Number,
    operatingReserves: Number,
    systemDemandDescription: String,
  }
  static targets = ["currentSystemLoad", "last5MinutesPace", "last60MinutesPace", "lastDemandUpdate",
    "lastDemandPaceUpdate", "todaysEstimatedPeak", "todaysEstimatedPeakNotice", "operatingReserves"]

  initialize() {
    this.chartSelector = document.getElementById("system-load-chart")
    this.compareToValue = this.defaultCompareWithSeries()

    const series = this.getSeries(this.currentValue)

    this.calculateLoadDiff(series)

    this.chartOptions = {
      series: series,
      stroke: {
        curve: 'stepline',
        lineCap: 'round'
      },
      colors: ['#00761A', '#9C0F00', '#F3AF27', '#006AE9'],
      chart: {
        animations: { enabled: true },
        height: '620px',
        type: 'line',
        stacked: false,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: 'zoom'
        },
        events: {
          beforeResetZoom: () => {
            this.lastZoom = null;
          },
          beforeZoom: (chartContext, { xaxis }) => {
            this.lastZoom = [xaxis.min, xaxis.max];
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0,
      },
      title: {
        text: '',
        align: 'left'
      },
      yaxis: {
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
        title: {
          text: 'Current System Demand'
        },
        min: (minimum) => {
          if (this.lastZoom) {
            let subset = this.chart.series.w.config.series.flatMap((serie) =>
              this.getSubSerieFromZoom(serie, this.lastZoom)
            )

            return Math.min(...subset) * 0.99
          }
          return minimum

        },
        max: (maximum) => {
          if (this.lastZoom) {
            let subset = this.chart.series.w.config.series.flatMap((serie) =>
              this.getSubSerieFromZoom(serie, this.lastZoom)
            )

            return Math.max(...subset) * 1.01
          }
          return maximum

        },
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: 'Hour of the day'
        },
        labels: {
          datetimeUTC: false
        }
      },
      tooltip: {
        shared: true,
        y: {
          formatter: function (val) {
            return val;
          }
        },
        x: {
          formatter: function (time) {
            let date = new Date(time);
            return `${date.getHours().toString().padStart(2,'0')}:${date.getMinutes().toString().padStart(2, '0')}`;
          }
        }
      },
    }

    this.chart = new ApexCharts(
      this.chartSelector,
      this.chartOptions
    );
  }

  defaultCompareWithSeries() {
    const compareToDates = Object.keys(this.compareWithValue)

    if (compareToDates.length !== 0) {
      return this.compareWithValue[compareToDates[0]]
    }
  }

  getSubSerieFromZoom(serie, zoom) {
    let minIndex = serie.data.findIndex(function(element) {
      return new Date(element.x).getTime() > zoom[0]
    })
    let maxIndex = serie.data.findIndex(function(element) {
      return new Date(element.x).getTime() > zoom[1]
    })
    if (maxIndex === -1) {
      maxIndex = serie.data.length - 1
    }

    return serie.data.slice(minIndex - 1, maxIndex + 1).map(element => element.y)
  }

  getSeries(current) {
    let series = [
      {
        name: 'Current System Demand',
        data: current
      }
    ];

    Object.keys(this.compareWithValue).forEach((date) => {
      series.push(
        {
          name: date,
          data: this.compareWithValue[date],
        }
      )
    });

    return series;
  }

  updateChartSeries(newCurrent) {
    this.currentValue = newCurrent
    let newSeries = this.getSeries(newCurrent)

    this.chart.updateOptions({
      chart: { animations: { enabled: false} }
    })

    this.calculateLoadDiff(newSeries)

    this.chart.updateSeries(newSeries, false)

    if(this.lastZoom) {
      this.chart.zoomX(this.lastZoom[0], this.lastZoom[1])
    }

    this.chart.updateOptions({
      chart: { animations: { enabled: true} }
    })
  }

  calculateLoadDiff(series) {
    if (series.length === 1) {
      return this.currentLoadDiffValue = 0
    }

    const currentSerie = series[0].data
    const currentLoadEntry = currentSerie[currentSerie.length - 1]
    const currentLoadTime = new Date(currentLoadEntry.x).setMilliseconds(0)

    let previousLoadEntry = series[1].data.find(function(element) {
      const previousTime = new Date(element.x).setMilliseconds(0)

      return previousTime >= currentLoadTime
    })

    if (previousLoadEntry === undefined) {
      return this.currentLoadDiffValue = 0
    } else {
      return this.currentLoadDiffValue = currentLoadEntry.y - previousLoadEntry.y
    }
  }

  formatLiveTime(dateTime){
    return `${dateTime.getHours()}:${String(dateTime.getMinutes()).padStart(2, "0")}`
  }

  formatLiveDate(date){
    let formatedDate = date.toLocaleDateString("en-US", { day: "2-digit", month: "2-digit", year: "numeric" }).replace(/\//g, "-")
    return `${formatedDate} at ${date.getHours()}:${String(date.getMinutes()).padStart(2, "0")}`
  }

  currentLoadDiffValueChanged(value) {
    const maxHistoricalPeak = Math.max(...this.compareToValue.map( (element) => { return element.y } ))
    const todayEstimatedPeak = value <= 0 ?  maxHistoricalPeak - Math.abs(value) : maxHistoricalPeak + Math.abs(value)
    this.todaysEstimatedPeakTarget.textContent = this.formatNumber(todayEstimatedPeak)
    if (value === 0) {
      this.todaysEstimatedPeakNoticeTarget.classList.add('invisible')
      this.todaysEstimatedPeakNoticeTarget.classList.remove('visible')
    } else {
      this.todaysEstimatedPeakNoticeTarget.classList.add('visible')
      this.todaysEstimatedPeakNoticeTarget.classList.remove('invisible')
    }
  }

  currentValueChanged(newValue) {
    if(newValue.length == 0) return undefined

    let lastReading = newValue.slice(-1)[0]
    let last5MinutesReading = newValue.slice(-5)[0]
    let last60MinutesReading = newValue.slice(-60)[0]
    let last5MinutesPace = lastReading["y"] - last5MinutesReading["y"]
    let last60MinutesPace = lastReading["y"] - last60MinutesReading["y"]

    this.currentSystemLoadTarget.textContent = this.formatNumber(lastReading["y"])
    this.currentSystemLoadTarget.setAttribute("title", this.systemDemandDescriptionValue)
    this.last5MinutesPaceTarget.textContent = this.formatNumber(last5MinutesPace)
    this.last60MinutesPaceTarget.textContent = this.formatNumber(last60MinutesPace)
    this.last5MinutesPaceTarget.className = last5MinutesPace <= 0 ? '' : 'text-danger'
    this.last60MinutesPaceTarget.className = last60MinutesPace <= 0 ? '' : 'text-danger'
    this.lastDemandUpdateTarget.textContent = this.formatLiveTime(new Date(lastReading["x"]))
    this.lastDemandPaceUpdateTarget.textContent = this.formatLiveTime(new Date(lastReading["x"]))
  }

  operatingReservesValueChanged(newValue) {
    this.operatingReservesTarget.textContent = this.formatNumber(newValue)
  }

  formatNumber(number) {
    return number.toLocaleString("en-US")
  }

  connect() {
    if (this.subscription === undefined) {
      this.subscription = this.connectToChannel(this)
    }

    if (!this.chartSelector.hasChildNodes()) {
      this.chart.render();

      let startTime = new Date();
      startTime.setHours(startTime.getHours() - 1);

      let endTime = new Date();
      endTime.setHours(endTime.getHours() + 1);

      this.chart.zoomX(startTime.getTime(), endTime.getTime());
    }
  }

  disconnect() {
    this.consumer.subscriptions.remove(this.subscription)
  }

  connectToChannel(source) {
    this.consumer = createConsumer()

    return this.consumer.subscriptions.create("CurrentLoadChannel", {
      received({ systemLoads, operatingReserves, systemDemandDescription }) {
        let newCurrent = systemLoads

        source.updateChartSeries(newCurrent)
        source.operatingReservesValue = operatingReserves.responseCapability
        source.systemDemandDescriptionValue = systemDemandDescription
      }
    })
  }
}

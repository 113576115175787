import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="highest-demand-day-picker"
export default class extends Controller {
  static outlets = ["date-picker"]

  triggerDateChange(event) {
    this.datePickerOutlet.dateValue = event.target.value
  }
}

import tinycolor from "tinycolor2"
import { Controller } from "@hotwired/stimulus"

TODAY_STROKE_COLOR = "#000000"
MIN_PEAK_OFFSET_VALUE = 3000
MAX_PEAK_OFFSET_VALUE = 1000
DEFAULT_MIN_TEMP_VALUE = 75
FOUR_CP_COLORS = {
  none: "#6C757D",
  low: "#00761A",
  medium: "#F3AF27",
  high: "#9C0F00",
}

// Connects to data-controller="weekly-summary"
export default class extends Controller {
  static targets = ["chart"]
  static values = {
    dates: Array,
    temperatures: Array,
    peaks: Array,
    fourCpProbabilities: Array,
    curtailmentWindows: Array,
  }

  minYAxis() {
    // Identity function
    //   > [12333, NaN].filter((x) => x)
    //   > [12333]
    const peaksCopy = this.peaksValue.filter((peak) => peak)
    const minPeak = Math.min(...peaksCopy)

    return (minPeak - MIN_PEAK_OFFSET_VALUE)
  }

  formatDateLabel(dateString) {
    const date = moment(dateString)

    return date.format("dddd MMMM D")
  }

  curtailmentWindowAnnotations() {
    return this.curtailmentWindowsValue
      .map(({ start, end, date }) => {
        return {
          x: this.formatDateLabel(date),
          y: this.minYAxis() - 1000,
          strokeDashArray: 0,
          borderColor: "#9C0F00",
          label: {
            borderColor: "#9C0F00",
            style: {
              color: "#fff",
              background: "#9C0F00"
            },
            text: ["Curtailment Window", `${start} - ${end}`]
          }
        }
      })
  }

  initialize() {
    var options = {
      annotations: {
        points: this.curtailmentWindowAnnotations(),
      },
      colors: ['#6C757D', '#f3af27'],
      series: [{
        name: 'System Peak',
        type: 'column',
        data: this.peaksSerieWithColors(),
      }, {
        name: 'Average High Temperature',
        type: 'line',
        data: this.temperaturesSerie(),
      }],
      chart: {
        height: 450,
        type: 'line',
        toolbar: {
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
          }
        },
      },
      stroke: {
        width: 4,
      },
      dataLabels: {
        enabled: true,
        enabledOnSeries: [1]
      },
      xaxis: {
        type: 'category',
        labels: {
          formatter: (dateString) => {
            return this.formatDateLabel(dateString)
          }
        },
      },
      yaxis: [{
        min: (minimum) => {
          return this.minYAxis()
        },
        max: (maximum) => {
          const peaksCopy = this.peaksValue.filter((peak) => peak)
          const maxPeak = Math.max(...peaksCopy)

          return (maxPeak + MAX_PEAK_OFFSET_VALUE)
        },
        title: {
          text: 'Peak MW',
        },
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            return (val ?? 0).toFixed(0);
          },
        },

      }, {
        opposite: true,
        title: {
          text: 'Average High Temperatures',
        },
        min: (minimum) => {
          const tempsCopy = this.temperaturesValue.filter((temperature) => temperature)
          tempsCopy.push(DEFAULT_MIN_TEMP_VALUE)

          return Math.min(...tempsCopy)
        },
        labels: {
          formatter: function (val) {
            return (val ?? 0).toFixed(0) + ' F';
          },
        },
      }]
    };

    var chart = new ApexCharts(this.chartTarget, options);
    chart.render();
  }

  peaksSerieWithColors() {
    return this.peaksValue.map((peak, index) => {
      const probability = this.fourCpProbabilitiesValue[index] ?? "none"
      const date = this.datesValue[index]
      const fillColor = FOUR_CP_COLORS[probability]
      const strokeColor = this.isToday(date) ? TODAY_STROKE_COLOR : fillColor

      return { x: date, y: peak, strokeColor, fillColor }
    })
  }

  temperaturesSerie() {
    return this.temperaturesValue.map((temperature, index) => {
      return { x: this.datesValue[index], y: temperature }
    })
  }

  darkenColor(color) {
    return tinycolor(color).darken().toString()
  }

  isToday(date){
    return moment().startOf("day").valueOf() == moment(date).valueOf()
  }

  isWeekend(date) {
    return moment(date).day() === 0 || moment(date).day() === 6
  }
}

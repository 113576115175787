import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="month-picker"
export default class extends Controller {
  static targets = ["monthInput"]

  initialize() {
    $(this.monthInputTarget).on("changeDate", () => {
      this.submitSelectedMonth()
    })
  }

  submitSelectedMonth() {
    let destinationUrl = new URL(window.location)

    destinationUrl.searchParams.set("month", this.monthInputTarget.value)
    window.location.href = destinationUrl.toString()
  }
}

import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

// Connects to data-controller="system-load-tendency"
export default class extends Controller {
  static values = { currentSystemLoad: Array, historicalSystemLoad: Array, historicalDate: String, currentLoadDiff: Number }
  static targets = ["loadDifference", "loadTendency"]

  connect() {
    if (this.subscription === undefined) {
      this.subscription = this.connectToChannel(this)
    }
  }

  initialize() {
    this.calculateLoadDiff()
  }

  calculateLoadDiff() {
    if (this.historicalSystemLoadValue.length === 0) {
      return this.currentLoadDiffValue = 0
    }

    const currentSerie = this.currentSystemLoadValue
    const currentLoadEntry = currentSerie[currentSerie.length - 1]
    const currentLoadTime = new Date(currentLoadEntry.x).setMilliseconds(0)

    let previousLoadEntry = this.historicalSystemLoadValue.find(function(element) {
      const previousTime = new Date(element.x).setMilliseconds(0)

      return previousTime >= currentLoadTime
    })

    if (previousLoadEntry === undefined) {
      return this.currentLoadDiffValue = 0
    } else {
      return this.currentLoadDiffValue = currentLoadEntry.y - previousLoadEntry.y
    }
  }

  currentLoadDiffValueChanged(value) {
    this.loadTendencyTarget.textContent = value <= 0 ? 'less than' : 'greater than'
    this.loadTendencyTarget.className = value <= 0 ? 'text-success' : 'text-danger'
    this.loadDifferenceTarget.textContent = this.formatNumber(Math.abs(value))
  }

  currentSystemLoadValueChanged(value) {
    this.calculateLoadDiff()
  }

  formatNumber(number) {
    return number.toLocaleString("en-US")
  }

  disconnect() {
    this.consumer.subscriptions.remove(this.subscription)
  }

  connectToChannel(source) {
    this.consumer = createConsumer()

    return this.consumer.subscriptions.create("CurrentLoadChannel", {
      received({ systemLoads }) {
        let newCurrent = systemLoads

        source.currentSystemLoadValue = newCurrent
      }
    })
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fifteen-minute-interval-chart"
export default class extends Controller {
  static targets = ["chart"]
  static values = {
    current: Array,
    compareWith: Object,
  }

  initialize() {
    var options = {
      series: this.getSeries(),
      stroke: {
        curve: 'smooth',
        lineCap: 'square'
      },
      colors: ['#00761A', '#9C0F00', '#f3af27'],
      chart: {
        animations: { enabled: true },
        height: '620px',
        type: 'line',
        stacked: false,
        zoom: {
          type: 'x',
          enabled: true,
          autoScaleYaxis: true
        },
        toolbar: {
          autoSelected: 'zoom'
        },
        events: {
          beforeResetZoom: () => {
            this.lastZoom = null;
          },
          beforeZoom: (chartContext, { xaxis }) => {
            this.lastZoom = [xaxis.min, xaxis.max];
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0,
      },
      title: {
        text: '',
        align: 'left'
      },
      yaxis: {
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
        title: {
          text: 'Current System Demand'
        },
      },
      xaxis: {
        type: 'datetime',
        title: {
          text: 'Hour of the day'
        },
        labels: {
          datetimeUTC: false
        }
      },
      tooltip: {
        shared: true,
        y: {
          formatter: function (val) {
            return val.toFixed(0);
          }
        },
        x: {
          formatter: function (time) {
            let date = new Date(time);
            return `${date.getHours().toString().padStart(2,'0')}:${date.getMinutes().toString().padStart(2, '0')}`;
          }
        }
      },
    }

    this.chart = new ApexCharts(this.chartTarget, options);
  }

  connect() {
    if (!this.chartTarget.hasChildNodes()) {
      this.chart.render();
    }
  }

  getSeries() {
    let series = [
      {
        name: 'Current System Demand',
        data: this.currentValue,
      }
    ];

    Object.keys(this.compareWithValue).forEach((date) => {
      series.push(
        {
          name: date,
          data: this.compareWithValue[date],
        }
      )
    });

    return series;
  }
}

import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

// Connects to data-controller="current-temperatures"
export default class extends Controller {
  connect() {
    if (this.subscription === undefined) {
      this.subscription = this.connectToChannel(this)
    }
  }

  disconnect() {
    this.consumer.subscriptions.remove(this.subscription)
  }

  connectToChannel(source) {
    this.consumer = createConsumer()

    return this.consumer.subscriptions.create("CurrentTemperaturesChannel", {
      received() {
        source.element.requestSubmit();
      }
    })
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="date-picker"
export default class extends Controller {
  static targets = ["dateInput"]
  static values = {
    date: String
  }

  initialize() {
    $(this.dateInputTarget).on("changeDate", () => {
      this.dateValue = this.dateInputTarget.value
    })
  }

  dateValueChanged(value, previousValue) {
    // avoid reloading on first load of datepicker
    if (previousValue === undefined || previousValue === "") { return }

    let destinationUrl = new URL(window.location)
    let selectedDates = new URLSearchParams(destinationUrl.search).getAll("dates[]")
    let previousDateIndex = selectedDates.indexOf(previousValue)

    // replace the old value with the new one to honor original order, or push the new value otherwise
    if (previousDateIndex === -1) {
      selectedDates.push(value)
    } else {
      selectedDates.splice(previousDateIndex, 1, value)
    }

    destinationUrl.searchParams.delete("dates[]")
    selectedDates.forEach((date) => destinationUrl.searchParams.append("dates[]", date))

    window.location.href = destinationUrl.toString()
  }
}

import { Controller } from "@hotwired/stimulus"

DEFAULT_MINIMUM_CHART_VALUE = 75

// Connects to data-controller="dashboard"
export default class extends Controller {
  static targets = ["chart"]
  static values = {
    xaxis: Array,
    averages: Array,
  }

  initialize() {
    this.chartColors = ["#006AE8"];
    this.chartOptions = {
      chart: {
        height: 257,
        type: 'bar',
        stacked: true
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '20%'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      series: [{
        name: 'Average',
        data: this.averagesValue,
      }],
      zoom: {
        enabled: false
      },
      legend: {
        show: false
      },
      colors: this.chartColors,
      xaxis: {
        categories: this.xaxisValue,
        axisBorder: {
          show: false
        },
      },
      yaxis: {
        forceNiceScale: true,
        axisTicks: {
          show: true,
          borderType: "dotted",
          color: "#e3eaef"
        },
        labels: {
          formatter: function (val) {
            return Number(val).toFixed(1) + "F"
          }
        },
        min: this.chartMin(),
        tickAmount: 5,
        title: {
          text: "Degrees F",
          color: "#e3eaef"
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function (val) {
            return val + "F"
          }
        },
      },
    }

    this.chart = new ApexCharts(
      this.chartTarget,
      this.chartOptions
    );
  }

  connect() {
    if (!this.chartTarget.hasChildNodes()) {
      this.chart.render();
    }
  }

  chartMin() {
    const seriesData = this.averagesValue.filter(value => value)

    seriesData.push(DEFAULT_MINIMUM_CHART_VALUE)

    return parseInt(
      Math.min(...seriesData)
    )
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="datetime-in-zone"
export default class extends Controller {
  static values = {
    datetime: String,
    timezone: String,
  }
  static targets = ["datetime"]

  connect() {
    this.datetimeTarget.textContent = this.formattedDate()
  }

  formattedDate() {
    return new Date(this.datetimeValue).toLocaleTimeString('en-US', {
      timezone: this.timezoneValue,
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
    })
  }
}

import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

// Connects to data-controller="real-time-system-demand"
export default class extends Controller {
  static values = { currentSystemLoad: Number, currentSystemLoadData: Array, lastUpdatedAt: String }
  static targets = ["systemLoad", "lastUpdatedAt"]

  connect() {
    if (this.subscription === undefined) {
      this.subscription = this.connectToChannel(this)
    }
  }

  initialize() {
    this.getSystemLoad()
  }

  getSystemLoad() {
    if (this.currentSystemLoadDataValue.length === 0) {
      return this.currentSystemLoadValue = 0
    }

    const currentLoad = this.currentSystemLoadDataValue[this.currentSystemLoadDataValue.length - 1]

    this.lastUpdatedAtValue = currentLoad.x
    return this.currentSystemLoadValue = currentLoad.y
  }

  currentSystemLoadDataValueChanged(value) {
    this.getSystemLoad()
  }

  currentSystemLoadValueChanged(value) {
    this.systemLoadTarget.textContent = `${value.toLocaleString("en-US")} MW`
  }

  lastUpdatedAtValueChanged(value) {
    const formattedDate = this.formatLiveTime(new Date(value))
    this.lastUpdatedAtTarget.textContent = formattedDate
  }

  formatLiveTime(dateTime){
    return `${dateTime.getHours()}:${String(dateTime.getMinutes()).padStart(2, "0")}`
  }

  disconnect() {
    this.consumer.subscriptions.remove(this.subscription)
  }

  connectToChannel(source) {
    this.consumer = createConsumer()

    return this.consumer.subscriptions.create("CurrentLoadChannel", {
      received({ systemLoads }) {
        let newCurrent = systemLoads

        source.currentSystemLoadDataValue = newCurrent
      }
    })
  }
}

import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="users"
export default class extends Controller {
  connect() {
    $('#users:not(.dataTable)').DataTable({
      order: [[2, 'asc'], [0, 'asc']],
      pageLength: 25,
      language: {
        paginate: {
          previous: "<i class='mdi mdi-chevron-left'>",
          next: "<i class='mdi mdi-chevron-right'>",
        }
      },
      drawCallback: function () {
        $('.dataTables_paginate > .pagination').addClass('pagination-rounded');
      }
    });
  }
}

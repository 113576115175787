import { Controller } from "@hotwired/stimulus"
import { createConsumer } from "@rails/actioncable"

DEFAULT_MINIMUM_CHART_VALUE = 50000

// Connects to data-controller="system-peak"
export default class extends Controller {
  static values = { series: Array }

  initialize(){
    this.chartSelector = document.getElementById("system-peak-chart")

    this.chartOptions = {
      series: this.seriesValue,
      colors: ['#006AE9', '#00761A'],
      chart: {
        animations: { enabled: true },
        height: '350px',
        type: 'bar'
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '30%'
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      fill: {
        opacity: 1
      },
      yaxis: {
        forceNiceScale: true,
        labels: {
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
        title: {
          text: 'MW'
        },
        min: this.chartMin(),
      },
    }

    this.chart = new ApexCharts(
      this.chartSelector,
      this.chartOptions
    );
  }

  connect() {
    if (!this.chartSelector.hasChildNodes()) {
      this.chart.render();
    }
  }

  chartMin() {
    const flattenedLoads = this.seriesValue
      .flatMap((serie) => serie["data"].map((data) => data["y"]))
      .filter(value => value)

    flattenedLoads.push(DEFAULT_MINIMUM_CHART_VALUE)

    return parseInt(
      Math.min(...flattenedLoads)
    )
  }
}
